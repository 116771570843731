import { Schema as S } from "effect";
import { yupResolver } from "@hookform/resolvers/yup";
import { redirect } from "@remix-run/node";
import { Effect, pipe } from "effect";
import type * as yup from "yup";
import { RemixArgsContext } from "~/application/contexts/RemixArgsContext";
import { SupabaseService } from "~/application/services/SupabaseService";
import { UrlSearchParams } from "~/application/services/UrlSearchParams";
import { effectAction } from "~/remix-effect/effectAction.server";
import { effectLoader } from "~/remix-effect/effectLoader.server";
import { validateRemixHookFormOrReturnJsonErrors } from "~/remix-effect/validateRemixHookForm";
import { LoginSignUpPage } from "./LoginSignUpPage";
import { emailFormSchema } from "./emailFormSchema";
import { useLoaderData } from "@remix-run/react";
import { UserId } from "~/application/services/UserId";

export type LoginFormValues = yup.InferType<typeof emailFormSchema>;

export const resolver = yupResolver(emailFormSchema);

export const loader = effectLoader(
  Effect.gen(function* ($) {
    const userId = yield* $(UserId.get());

    const { redirectTo, prefillEmail } = yield* $(
      UrlSearchParams.get(
        S.Struct({
          redirectTo: S.optional(S.String),
          prefillEmail: S.optional(S.String),
        })
      )
    );

    if (userId) {
      return redirect(redirectTo || "/");
    } else {
      return {
        prefillEmail,
      };
    }
  })
);

export const action = effectAction(
  Effect.gen(function* ($) {
    const { supabaseAdmin } = yield* $(SupabaseService);
    const {
      context: { origin },
    } = yield* $(RemixArgsContext);

    const {
      validatedFormValues: { email, mainApplication },
    } = yield* $(
      pipe(
        RemixArgsContext,
        Effect.flatMap(validateRemixHookFormOrReturnJsonErrors(resolver))
      )
    );

    const searchParams = yield* $(
      UrlSearchParams.get(
        S.partial(
          S.Struct({
            navigateAfterLogin: S.String,
          })
        )
      )
    );

    const signInResult = yield* $(
      Effect.tryPromise({
        try: () =>
          supabaseAdmin.auth.signInWithOtp({
            email,
            options: {
              shouldCreateUser: true,
              emailRedirectTo: `${origin}/login/email-redirect`,
              data: {
                mainApplication,
              },
            },
          }),
        catch: (e) => console.log(e),
      })
    );

    console.log(signInResult);
    const { navigateAfterLogin } = searchParams;

    return redirect(
      `otp?email=${encodeURIComponent(email)}${
        navigateAfterLogin
          ? `&navigateAfterLogin=${encodeURIComponent(navigateAfterLogin)}`
          : ""
      }`
    );
  })
);

export default function Index() {
  const { prefillEmail } = useLoaderData<typeof loader>();
  return <LoginSignUpPage variant={"login-api"} prefillEmail={prefillEmail} />;
}
